import { gettext } from "#js/components/i18n"
import { message } from "#js/components/snackbar"

let reloadPrompted = false

/**
  Display an error message and prompt the user to reload the page.
  @param {string} msg - The message to display.
 */
export function errorReload(msg) {
  if (!reloadPrompted) {
    message(
      msg ||
        gettext(
          "Ups, there seems to be connection issue.<br>Check your network connection and reload this page.",
        ),
      "error",
      gettext("reload"),
      () => globalThis.location.reload(),
      false,
    )
    reloadPrompted = true
  }
}

/**
 * Error class for HTTP errors.
 * @param {Response} response - HTTP response.
 * @returns {void}
 */
export class HttpError extends Error {
  constructor(response) {
    super(`HTTP ${response.status}: ${response.statusText}`)
    this.response = response
  }
}

/**
 * Return response if no error, otherwise throw HttpError.
 * @param {Response} response - HTTP response.
 * @throws {HttpError}, if response is not OK.
 * @returns {void}
 */
export function handleHttpErrors(response) {
  if (response.ok) {
    return response
  } else {
    throw new HttpError(response)
  }
}

/**
 * IO error handler.
 * @callback onIoError
 * @returns {void}
 */

/**
 * Fetch JSON from given URL.
 * @param {string} url - URL to fetch JSON from.
 * @param {object} options - Options to pass to fetch.
 * @param {object} defaultValue - a value provided in case of a handled exception
 * @param {onIoError} onIoError - A function to call on IO error.
 * @returns {Promise<object>} - Promise that resolves to JSON.
 */
export async function fetchJSON(url, options, defaultValue, onIoError = errorReload) {
  const defaultOptions = {}
  try {
    defaultOptions.headers = {
      "X-CSRFToken": globalThis.csrfToken ||
        document.querySelector('input[name="csrfmiddlewaretoken"]').value,
    }
  } catch (e) {
    console.warn("CSRF token not found", e)
  }
  const json = await fetch(url, { ...defaultOptions, ...options })
    .then(handleHttpErrors)
    .then((response) => response.json())
    .catch((error) => {
      if (error instanceof HttpError) {
        throw error
      } else {
        console.warn(error)
        onIoError()
      }
    })

  return json || defaultValue
}

/**
 * Convert a JavaScript object to URL query.
 * @param {object} object - JSON to convert.
 * @returns {URLSearchParams} - URL query.
 */
export function objectToURLParams(object) {
  const query = new URLSearchParams()
  for (const [key, value] of Object.entries(object)) {
    if (Array.isArray(value)) { // flatten array values
      value.forEach((v) => query.append(key, v))
    } else if (value instanceof Date) {
      query.append(key, Date(value).format("Y-m-d"))
    } else if (value || typeof value === "boolean") { // don't null values
      query.append(key, object[key])
    }
  }
  return query
}

/**
 * Post data to url using FormData.
 * @param {string} url - URL to post data to.
 * @param {object} data - Data to post.
 * @param {string} method - HTTP method.
 * @returns {Promise<Response>} - A Promise that resolves to a Response object.
 * @throws {Error} - If CSRF token is not found.
 */
export async function formPost(url, data, method = "POST") {
  let token
  const formData = new FormData()
  for (const name in data) {
    formData.append(name, data[name])
  }
  try {
    token = globalThis.csrfToken ||
      document.querySelector('input[name="csrfmiddlewaretoken"]').value
  } catch (e) {
    throw new Error("CSRF token not found")
  }
  const request = new Request(
    url,
    {
      method,
      headers: { "X-CSRFToken": token },
      mode: "same-origin", // don't send CSRF token to other domains
      body: formData,
    },
  )
  return await fetch(request)
}

/**
 * Return a function that, when called, redirects the user to the URL.
 * @param {string} url -- The URL to redirect to.
 * @returns {Function} -- A function that, when called, redirects the user to the URL.
 */
export function goToCallback(url) {
  return () => {
    globalThis.location.href = url
  }
}
