import {
  fromAlgoliaDate,
  getAlgoliaAnalyticsData,
} from "#js/integrations/algoliaSearch"
import { localize, placeholders } from "#js/components/utils"
import { msg, str } from "@lit/localize"
import { TrackingMixin } from "#js/components/TrackingMixin"
import { html } from "lit"

export class EventList extends TrackingMixin {
  static properties = {
    events: { type: Array },
    error: { type: Boolean, attribute: false },
    phone: { type: String },
    formattedPhone: { type: String },
  }

  applySettings(settings) {
    this.eventTransformer = new AlgoliaEventTransformer(
      settings.watched_offers,
    )
  }

  render() {
    if (this.error) {
      return html`
        <link rel="stylesheet" href="${globalThis.styleFilePath}">
        <div class="discovery-list">
          <div class="discovery-list__error">
            <h2>
              ${msg(str`Sorry something went wrong.`)}
            </h2>
            <p>${msg(str`Please try again later.`)}</p>
            <svg viewBox="0 0 300 300" class="discovery-list__error__image"
                 style="height: 8em">
              <use href="${globalThis.svgSprite}#Schubkarre">
              </use>
            </svg>
          </div>
        </div>
      `
    }
    // `this.events` is undefined until the first search result is received
    if (this.events === undefined) {
      return html`
        <link rel="stylesheet" href="${globalThis.styleFilePath}">
        <div class="discovery-list">
          <div class="discovery-list__loading">
            <svg viewBox="0 0 32 32" class="discovery-list__loading__svg">
              <g>
                <use href="${globalThis.svgSprite}#voFerienaktivitaet">
                </use>
                <animateTransform attributeType="xml" attributeName="transform"
                                  type="rotate" from="360 16 16" to="0 16 16" dur="3s"
                                  additive="sum" repeatCount="indefinite"/>
              </g>
            </svg>
          </div>
        </div>
      `
    }
    if (this.events.length === 0) {
      return html`
        <link rel="stylesheet" href="${globalThis.styleFilePath}">
        <div class="discovery-list">
          <div class="discovery-list__error layout">
            <h2>
              ${msg(str`Oops, looks like we couldn't find any matching results…`)}
              <div class="subtitle">
                <small style="font-size: 70%">
                  ${
        msg(str`…but our service team is always here to help you find the right offer.`)
      }
                </small>
              </div>
            </h2>
            <div class="row row--centered">
              <div style="text-align: start">
                <div>
                <a href="tel:${this.phone}">
                  <svg class="icon icon--large">
                    <use href="${globalThis.svgSprite}#voPhone">
                    </use>
                  </svg>
                  <strong>${this.formattedPhone}</strong>
                </a>
                <span>
                  (${msg("Mo–Fr 9AM–6PM")})
                </span>
                </div>
                <div>
                <a href="mailto:beratung@voiio.app">
                  <svg class="icon icon--large">
                    <use href="${globalThis.svgSprite}#voMail">
                    </use>
                  </svg>
                  <strong>beratung@voiio.app</strong>
                </a>
                  </div>
              </div>
              <svg viewBox="0 0 483 398" height="250" class="desktop-only">
                <use href="${globalThis.svgSprite}#womanHeadset">
                </use>
              </svg>
            </div>
          </div>
        </div>
      `
    }
    return html`
      <link rel="stylesheet" href="${globalThis.styleFilePath}">
      <div class="discovery-list">
        ${
      this.events.map((event) =>
        html`
          <offer-card .offer=${event}
                      promotionId=${this.trackingInformation.promotion_id}
                      promotionName=${this.trackingInformation.promotion_name}
                      itemListId=${this.trackingInformation.item_list_id}
                      itemListName=${this.trackingInformation.item_list_name}
          ></offer-card>
        `
      )
    }
      </div>
    `
  }

  eventsChanged(events, hitsPerPage, currentPage, algoliaQueryId) {
    this.events = events.map(
      (hit, index) =>
        this.eventTransformer.transform(
          hit,
          index,
          hitsPerPage,
          currentPage,
          algoliaQueryId,
        ),
    )
  }
}

customElements.define("event-list", EventList)

export class EventSlider extends TrackingMixin {
  static properties = {
    events: { type: Array, attribute: false },
  }

  applySettings(settings) {
    this.eventTransformer = new AlgoliaEventTransformer(
      settings.watched_offers,
    )
  }

  render() {
    return html`
      <link rel="stylesheet" href="${globalThis.styleFilePath}"/>
      <section>
        <h2>${this.title}</h2>
        <div class="layout">
          <div class="slider-grid">
            ${this.events ? [...this.renderEvents(this.events)] : placeholders(4)}
          </div>
        </div>
      </section>
    `
  }

  *renderEvents(events) {
    for (let i = 0; i < 4; i++) {
      yield html`
        <offer-slider-card .offer="${events[i]}"
                           sliderPosition="${i}"
                           promotionId="${this.promotionId}"
                           promotionName="${this.promotionName}"
        >
        </offer-slider-card>
      `
    }
  }

  eventsChanged(events, hitsPerPage, currentPage, algoliaQueryId) {
    if (events.length === 0) {
      this.remove()
    } else {
      this.events = events.map(
        (hit, index) =>
          this.eventTransformer.transform(
            hit,
            index,
            hitsPerPage,
            currentPage,
            algoliaQueryId,
          ),
      ).sort((a, b) => b.sort_order - a.sort_order)
    }
  }
}

customElements.define("event-slider", EventSlider)

export class AlgoliaEventTransformer {
  constructor(watchedOffers) {
    this.watchedOffers = watchedOffers
  }

  /**
   * Transform an Algolia search result item to an event for the offer card.
   * @param {object} hit - Algolia search result item
   * @param {number} index - index of the item in the search result
   * @param {number} hitsPerPage - number of hits per page
   * @param {number} currentPage - current page number
   * @param {number} algoliaQueryId - ID to identify the initial search request
   * @returns {object} event - transformed event
   */
  transform(hit, index, hitsPerPage, currentPage, algoliaQueryId) {
    const type = hit.objectID.split("-")[0]
    let title = hit.title
    let subtitle = hit.parent_object_title
    let watched = false
    let bookOptionTimer = null

    if (["bookingoption", "offerlist", "targetgroup", "topic"].includes(type)) {
      subtitle = hit.parent_object_subtitle
    }

    // different talks share the same subtitle from the same series,
    // so we display description (aka abstract) instead
    if (type === "talk") {
      subtitle = hit.description
    }

    if (type === "bookingoption") {
      const parentObjectId = parseInt(hit.parent_object_id.split("-")[1])
      watched = this.watchedOffers.includes(parentObjectId)
      title = hit.parent_object_title
    }

    if (hit.start_date) {
      bookOptionTimer = localize(fromAlgoliaDate(hit.start_date))
    }

    const languages = hit.languages?.reduce((accumulator, code) => {
      const key = code.toLowerCase()
      accumulator[key] = code
      return accumulator
    }, {})

    return {
      type,
      sort_order: hit._score,
      id: hit.id,
      url: hit.public_url,
      public_url: hit.public_url,
      activity_type: hit.activity_type,
      activity_type_display: hit.activity_type_display,
      booking_option_timer: bookOptionTimer,
      location: hit.location,
      age_range: hit.age_range_display,
      provider_title: hit.provider_title,
      job_title: hit.job_title,
      areas_of_expertise: hit.areas_of_expertise,
      brand: hit.brand_name,
      watch_url: hit.watch_url,
      unwatch_url: hit.unwatch_url,
      languages: languages || {},
      eap_support: hit.eap_support,
      title,
      subtitle,
      watched,
      picture: hit.picture,
      host_picture: hit.host_picture,
      algoliaAnalytics: getAlgoliaAnalyticsData(
        hit,
        index,
        hitsPerPage,
        currentPage,
        algoliaQueryId,
      ),
    }
  }
}
