import { css, html, LitElement } from "lit"
import { createRef, ref } from "lit/directives/ref.js"
import { msg, str, updateWhenLocaleChanges } from "@lit/localize"
import { atBreakpoint } from "#js/components/styles"
import { setLocale } from "#js/components/lit-i18n"

export class PersonalizeSlider extends LitElement {
  static get properties() {
    return {
      id: { type: String },
      apiUrl: { type: String },
      listUrl: { type: String },
      updateUrl: { type: String },
      preferenceUrl: { type: String },
      title: { type: String },
      question: { type: String },
      fieldToPatch: { type: String },
      trueValue: { type: String },
      falseValue: { type: String },
    }
  }

  static styles = css`
     ${
    atBreakpoint(
      "mobile",
      css`
      .personalize-slider__question {
        background: radial-gradient(ellipse, rgb(245 245 245 / 95%) 40%, rgb(245 245 245 / 30%) 90%) !important;
      }

      .personalize-slider__heading {
        max-width: 350px;
      }
    `,
    )
  }

    .personalize-slider__wrapper {
      position: relative;
      transition: all 0.7s ease-out;
      max-height: 800px;
      overflow: hidden;
    }

    .personalize-slider__question {
      position: absolute;
      background: radial-gradient(ellipse, var(--white) 40%, rgb(245 245 245 / 50%) 70%);
      width: 100%;
      height: 100%;
      z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: opacity 0.7s;
      padding: var(--space);
      text-align: center;
    }
  `

  constructor() {
    super()
    this.trueValue = true
    this.falseValue = false
    setLocale(globalThis.language)
    updateWhenLocaleChanges(this)
  }

  questionRef = createRef()
  wrapperRef = createRef()
  csrftoken = document.querySelector("input[name=csrfmiddlewaretoken]").value

  firstUpdated() {
    const mutationObserver = new MutationObserver(this.mutationCallback.bind(this))
    mutationObserver.observe(this.wrapperRef.value, {
      childList: true,
    })
  }

  /**
   * Listen to mutation events on the wrapper element child.
   * Remove component when child slider is removed.
   * @param {Event} event - Mutation event.
   */
  mutationCallback(event) {
    if (event[0].removedNodes) {
      this.remove()
    }
  }

  render() {
    return html`
      <link rel="stylesheet" href="${globalThis.styleFilePath}"/>
      <div class="personalize-slider__wrapper" ${ref(this.wrapperRef)}>
        <div class="personalize-slider__question" ${ref(this.questionRef)}>
          <h3 class="personalize-slider__heading">${this.question}</h3>
          <p>
            <small>${
      msg(
        html`You can change your preference anytime in your <a href="${this.preferenceUrl}">settings</a>.`,
      )
    }</small>
          </p>
          <div class="row" style="gap: 1em;">
            <button class="button button--outlined" @click=${this.hideQuestion}>
              ${msg(str`yes`)}
            </button>
            <button class="button button--outlined" @click=${this.hideSlider}>
              ${msg(str`no`)}
            </button>
          </div>
        </div>
        <offer-slider id="${this.id}"
                      apiUrl="${this.apiUrl}"
                      listUrl="${this.listUrl}"
                      title="${this.title}">
        </offer-slider>
      </div>
    `
  }

  async hideQuestion() {
    this.questionRef.value.style.opacity = 0
    setTimeout(() => {
      this.questionRef.value.style.display = "none"
    }, 700)
    this.wrapperRef.value.style.overflow = "visible"

    await fetch(this.updateUrl, {
      method: "PATCH",
      headers: {
        "X-CSRFToken": this.csrftoken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        [this.fieldToPatch]: this.trueValue,
      }),
    })
  }

  async hideSlider() {
    this.wrapperRef.value.style.maxHeight = 0
    this.wrapperRef.value.style.marginTop = "calc(-1 * var(--space))"

    await fetch(this.updateUrl, {
      method: "PATCH",
      headers: {
        "X-CSRFToken": this.csrftoken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        [this.fieldToPatch]: this.falseValue,
      }),
    })
    setTimeout(() => {
      this.remove()
    }, 700)
  }
}

globalThis.customElements.define("personalize-slider", PersonalizeSlider)
