import { gettext, pgettext } from "#js/components/i18n"
import Shepherd from "shepherd.js"
import { fetchJSON } from "#js/components/http"
import { offset } from "@floating-ui/dom"
import { ready } from "#js/components/utils"

const MODAL_PADDING_LARGE = 12
const MODAL_PADDING_MEDIUM = 6
const FLOATING_UI_OFFSET = 50

/**
 * Determine if the given path is a home path.
 * @param {string} path - The path to check.
 * @returns {boolean} true if the given path is a home path
 * [
 *  '/',               // should match
 *  '/en/',            // should match
 *  '/de/',            // should match
 *  '/fr/',            // should match
 *  '/zh-hans/',       // should match
 *  '/it/',            // should match
 *  '/es-es/',         // should match
 *  '/pt-br/',         // should match
 *  '/whatever/',      // should not match
 *  '/en/something',   // should not match
 *  '/de/extra',       // should not match
 *  '/fr/extra/',      // should not match
 *  '/zh-hans/extra',  // should not match
 * ];
 */
function isHomePath(path) {
  const homePathRegex = /^\/([a-z]{2}(?:-[a-z]{2,4})?\/)?$/
  return homePathRegex.test(path)
}

function getSteps(tour, settings) {
  const navBarCalendarText = gettext(
    "The <b>Program</b> shows you offers in a calendar view." +
      "<br><br><b>Please click on the Program tab</b>",
  )
  const calendarInfoText = gettext(
    "In the program you will find all <b>" +
      "offers that start soon and have been recommended for you</b>." +
      "<br><br>Perfect for booking an offer that fits your schedule.",
  )

  const nextButton = {
    text: gettext("next"),
    action: tour.next,
  }

  const cancelButton = {
    text: pgettext("verb", "exit"),
    action: tour.cancel,
    classes: "cancel-tour",
  }

  return [
    {
      id: "welcome",
      canShow: isHomePath(globalThis.location.pathname),
      text: gettext(
        "<b>Welcome to voiio!</b><br><br>" +
          "I am your assistant and will introduce you to the voiio platform!",
      ),
      buttons: [
        cancelButton,
        {
          text: pgettext("verb", "start"),
          action: tour.next,
        },
      ],
    },
    {
      id: "core-topics",
      canShow: isHomePath(globalThis.location.pathname),
      text: gettext(
        "Our 3 categories are the <b>foundation of voiio</b>.<br><br>" +
          "They improve the work-life balance, promote health," +
          " strengthen the team culture and offer help in challenging life situations.",
      ),
      attachTo: {
        element: '[data-tour="core-topics"]',
        on: "top",
      },
      buttons: [nextButton],
      canClickTarget: false,
      modalOverlayOpeningPadding: MODAL_PADDING_LARGE,
    },
    {
      id: "core-topic",
      canShow: isHomePath(globalThis.location.pathname),
      text: gettext(
        "Here you can see all topics that belong to a certain <b>category</b>.",
      ),
      attachTo: {
        element: '[data-tour="core-topic"]',
        on: "right",
      },
      buttons: [nextButton],
      canClickTarget: false,
      modalOverlayOpeningPadding: MODAL_PADDING_LARGE,
      floatingUIOptions: {
        middleware: [offset(FLOATING_UI_OFFSET)],
      },
    },
    {
      id: "topic",
      canShow: isHomePath(globalThis.location.pathname),
      text: gettext(
        "Within an offer category you can choose a <b>specific topic</b>" +
          " that interests you.<br><br><b>Please click on the highlighted topic</b>",
      ),
      attachTo: {
        element: '[data-tour="topic"]',
        on: "right",
      },
      canClickTarget: true,
      modalOverlayOpeningPadding: MODAL_PADDING_MEDIUM,
      floatingUIOptions: {
        middleware: [offset(FLOATING_UI_OFFSET)],
      },
    },
    {
      id: "topic-offer-list",
      canShow: globalThis.location.pathname.endsWith(
        `/topics/${settings.core_topic_slug}/`,
      ),
      text: gettext(
        "Here you can explore all <b>offers</b> for the topic that interests you.",
      ),
      attachTo: {
        element: '[data-tour="topic-offer-list"]',
        on: "top",
      },
      buttons: [nextButton],
      canClickTarget: false,
      modalOverlayOpeningPadding: MODAL_PADDING_LARGE,
    },
    {
      id: "nav-bar-calendar",
      canShow: globalThis.location.pathname.endsWith(
        `/topics/${settings.core_topic_slug}/`,
      ),
      text: navBarCalendarText,
      attachTo: {
        element: '[data-tour="nav-bar-calendar"]',
        on: "bottom",
      },
    },
    {
      id: "calendar-info",
      canShow: globalThis.location.pathname.endsWith("/program/"),
      text: calendarInfoText,
      buttons: [cancelButton, nextButton],
    },
    {
      id: "nav-bar-eap",
      canShow: (
        globalThis.location.pathname.endsWith("/program/") &&
        settings.has_eap_enabled
      ),
      text: gettext(
        "voiio combines thousands of low-threshold services with personalized counselling." +
          '<br><br><b>Please click on the "Counselling" tab for more information.</b>',
      ),
      attachTo: {
        element: '[data-tour="nav-bar-eap"]',
        on: "bottom-end",
      },
    },
    {
      id: "eap-info",
      canShow: (
        globalThis.location.pathname.endsWith("/experts/") &&
        settings.has_eap_enabled
      ),
      text: gettext(
        "By working with voiio, you give your employees access to a network of more " +
          "than 2000 different experts who can provide personalised advice in every " +
          "situation and phase of life.<br><br>Depending on the package you book with " +
          "voiio, your employees can book counselling sessions either free of charge or " +
          "self-financed.",
      ),
      attachTo: {
        element: '[data-tour="eap-offers"]',
      },
      buttons: [nextButton],
      canClickTarget: false,
      scrollTo: true,
      when: {
        show: function () {
          this.getTarget().scrollIntoView()
        },
      },
      modalOverlayOpeningPadding: MODAL_PADDING_LARGE,
    },
    {
      id: "nav-bar-concierge",
      canShow: (
        globalThis.location.pathname.endsWith("/experts/") &&
        settings.has_eap_enabled
      ),
      text: gettext(
        "You get tailored support for your unique needs." +
          '<br><br><b>Please click on the "Concierge" tab for more information.</b>',
      ),
      attachTo: {
        element: '[data-tour="nav-bar-concierge"]',
        on: "bottom-end",
      },
    },
    {
      id: "concierge-info",
      canShow: (
        globalThis.location.pathname.endsWith("/concierge/") &&
        settings.has_eap_enabled
      ),
      text: gettext(
        "Our concierge service takes the pressure off you at every stage of your life." +
          "<br><br>From support in finding a childcare place, emergency care to outpatient carers.",
      ),
      attachTo: {
        element: '[data-tour="concierge-offers"]',
      },
      buttons: [nextButton],
      canClickTarget: false,
      scrollTo: true,
      when: {
        show: function () {
          this.getTarget().scrollIntoView()
        },
      },
      modalOverlayOpeningPadding: MODAL_PADDING_LARGE,
    },
    {
      id: "finish",
      canShow: (
        globalThis.location.pathname.endsWith("/program/") ||
        (
          settings.has_eap_enabled && (
            globalThis.location.pathname.endsWith("/experts/") ||
            globalThis.location.pathname.endsWith("/concierge/")
          )
        )
      ),
      text: gettext(
        "<b>That's it!</b><br><br>" +
          "Now you can explore our topics the rest of the voiio platform yourself, have fun!",
      ),
      buttons: [
        {
          text: gettext("done"),
          action: tour.complete,
        },
      ],
    },
  ]
}

function updateProductTourConfig(data) {
  const csrftoken = document.querySelector("input[name=csrfmiddlewaretoken]").value

  fetch("/api/product-tour/update/", {
    method: "put",
    body: JSON.stringify(data),
    headers: {
      "X-CSRFToken": csrftoken,
      "Content-Type": "application/json",
    },
    credentials: "same-origin",
  })
}

export async function initProductTour() {
  const smallScreen = globalThis.innerWidth <= 1024

  if (smallScreen) return

  const settings = await fetchJSON("/api/product-tour/settings/")

  if (!settings) {
    console.warn("Failed to fetch product tour settings.")
    return
  }

  if (!settings.should_show_tour) return

  const tour = new Shepherd.Tour({
    useModalOverlay: true,
    exitOnEsc: false,
    keyboardNavigation: false,
    defaultStepOptions: {
      arrow: false,
      scrollTo: {
        behavior: "smooth",
        block: "center",
      },
    },
  })

  for (const step of getSteps(tour, settings)) {
    if (step.canShow) {
      tour.addStep(step)
    }
  }

  tour.on("show", (data) => {
    const tourConfig = {
      tour_steps_shown: [data.step.id],
    }

    if (data.step.id !== "welcome") {
      tourConfig.tour_status = "started"
    }

    updateProductTourConfig(tourConfig)

    globalThis.dataLayer.push({
      event: "product_tour_show",
      step_id: data.step.id,
    })
  })

  tour.on("cancel", (data) => {
    updateProductTourConfig({ tour_status: "cancelled" })

    globalThis.dataLayer.push({
      event: "product_tour_cancel",
      step_id: data.tour.currentStep.id,
    })
  })

  tour.on("complete", (data) => {
    if (data.tour.currentStep) {
      updateProductTourConfig({
        tour_steps_shown: [data.tour.currentStep.id],
        tour_status: "finished",
      })

      globalThis.Userback.openSurvey("mWdmIE")

      globalThis.dataLayer.push({
        event: "product_tour_finish",
      })
    }
  })

  tour.start()
}

ready(function () {
  document.addEventListener("cookie-consent-submitted", () => {
    initProductTour()
  })

  const cookieConsent = document.getElementById("cookie-consent")
  // Show tour only after cookie consent banner is gone
  if (cookieConsent) {
    initProductTour()
  }
})
