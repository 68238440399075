import "chartjs-adapter-dayjs-4"
import "dayjs/locale/de"
import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  Colors,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PieController,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { capFirst, localize, toPercentage } from "#js/components/utils"
import { gettext } from "#js/components/i18n"
import AnnotationPlugin from "chartjs-plugin-annotation"
import { color } from "chart.js/helpers"
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import weekOfYear from "dayjs/plugin/weekOfYear"
import { fetchJSON } from "#js/components/http"

dayjs.extend(advancedFormat)
dayjs.extend(weekOfYear)
dayjs.locale(globalThis.language)

Chart.register(
  Legend,
  LineController,
  LinearScale,
  PointElement,
  LineElement,
  Colors,
  ChartDataLabels,
  Filler,
  TimeScale,
  Tooltip,
  PieController,
  BarController,
  BarElement,
  CategoryScale,
  ArcElement,
  AnnotationPlugin,
  Title,
)

export function handleNoData(chart) {
  const firstDataset = chart.data.datasets[0]
  let hasData
  if (chart.config.type === "pie") {
    hasData = firstDataset.data.length !== 0
  } else {
    // We are dealing with a line chart
    hasData = firstDataset.data.some((item) => item.y !== 0)
  }

  if (!hasData) {
    const chartContext = chart.ctx

    // rerender chart content to highlight missing data
    chart.clear()
    chartContext.save()
    chartContext.textAlign = "center"
    chartContext.textBaseline = "middle"
    chartContext.fillText(
      capFirst(gettext("no data available yet")),
      chart.width / 2,
      chart.height / 2,
    )
    chartContext.restore()

    // Hide download buttons for respective chart
    const chartName = chart.canvas.dataset.chartName
    const downloadForm = document.getElementById(`form-downloads-${chartName}`)
    downloadForm.classList.add("hidden")
  }
}

const emptyGraphPlugin = {
  id: "emptyGraph",
  afterDraw: handleNoData,
}

/**
 * Render a pie chart in the given canvas element.
 *
 * Dataset attributes:
 * - url: URL to fetch the data from.
 */
export class PieChart extends HTMLCanvasElement {
  async connectedCallback() {
    const data = await fetchJSON(this.dataset.url)
    data.labels = data.datasets[0].labels
    for (const dataset of data.datasets) {
      dataset.rotation = 340
    }

    const globalStyle = globalThis.getComputedStyle(document.documentElement)
    const font = {
      lineHeight: parseFloat(globalStyle.lineHeight),
      family: globalStyle.fontFamily,
      size: parseFloat(globalStyle.fontSize),
    }

    return new Chart(this.getContext("2d"), {
      type: "pie",
      data,
      plugins: [ChartDataLabels, emptyGraphPlugin],
      options: {
        locale: document.documentElement.lang,
        aspectRatio: 3 / 2,
        maintainAspectRatio: true,
        responsive: true,
        plugins: {
          legend: {
            onHover: this.handlePieHoverLegend,
            onLeave: this.handlePieLeaveLegend,
            position: "left",
            align: "start",
            maxWidth: 120,
            labels: {
              generateLabels: this.generateLabels.bind(this),
              usePointStyle: true,
              font: {
                ...font,
                size: font.size * 0.8,
              },
            },
          },
          datalabels: {
            display: "auto",
            align: "end",

            formatter: (value, ctx) => {
              return toPercentage(
                value /
                  ctx.chart.getDatasetMeta(ctx.datasetIndex).total,
                0,
              )
            },
            color: "#fff",
            font: {
              ...font,
              weight: "bold",
              lineHeight: 1,
              size: font.size * 0.8,
            },
          },
          tooltip: {
            usePointStyle: true,
            titleMarginBottom: 0,
            callbacks: {
              title: this.setPieChartTooltip,
              label: () => null,
            },
          },
        },
      },
    })
  }

  generateLabels(chart) {
    const legendItems = Chart.overrides.pie.plugins.legend.labels.generateLabels(chart)
    for (const legendItem of legendItems) {
      legendItem.text = capFirst(
        legendItem.text.length > 10
          ? legendItem.text.slice(0, 10) + "…"
          : legendItem.text,
      )
    }
    return legendItems
  }

  handlePieHoverLegend(evt, legendItem, legend) {
    // Set the respective arc element to active and show the tooltip
    const meta = legend.chart.getDatasetMeta(0)
    const arc = meta.data[legendItem.index]
    arc.active = true
    const tooltip = legend.chart.tooltip
    tooltip.setActiveElements([
      {
        datasetIndex: 0,
        index: legendItem.index,
      },
    ])
    legend.chart.update()
  }

  handlePieLeaveLegend(evt, legendItem, legend) {
    // Set the respective arc element to inactive
    const meta = legend.chart.getDatasetMeta(0)
    const arc = meta.data[legendItem.index]
    arc.active = false // This also hides the tooptip
    legend.chart.update()
  }

  setPieChartTooltip(tooltipItems) {
    const tooltipItem = tooltipItems[0]
    if (tooltipItem.parsed !== null) {
      return `${tooltipItem.label} ${
        toPercentage(
          tooltipItem.parsed /
            tooltipItem.chart.getDatasetMeta(tooltipItem.datasetIndex).total,
          0,
        )
      }`
    }
  }
}

customElements.define("pie-chart", PieChart, { extends: "canvas" })

/**
 * Render a time series chart in the given canvas element.
 *
 * Dataset attributes:
 * - url: URL to fetch the data from.
 * - title: Title of the y-axis.
 * - unit: Unit of the y-axis.
 * - timescale: Timescale of the x-axis.
 * - metric: Metric to display.
 * - y: Suggested minimum value for the y-axis.
 * - annotation: Annotation to display.
 * - chartName: Name of the chart for download.
 */
export class TimeSeriesChart extends HTMLCanvasElement {
  async connectedCallback() {
    const data = await fetchJSON(this.dataset.url)
    const type = this.dataset.metric === "running_total" ? "line" : "bar"

    for (const [index, dataset] of data.datasets.entries()) {
      dataset.hidden = index !== 0
      dataset.backgroundColor = this.dataset.metric === "running_total"
        ? color(dataset.borderColor).alpha(0.5).rgbString()
        : dataset.borderColor
      dataset.fill = "origin"
      data.labels = dataset.labels
    }

    const font = {
      lineHeight: globalThis.getComputedStyle(document.documentElement).lineHeight,
      family: globalThis.getComputedStyle(document.documentElement).fontFamily,
      size: parseFloat(globalThis.getComputedStyle(document.documentElement).fontSize),
    }

    this.graph = new Chart(this.getContext("2d"), {
      type,
      data,
      plugins: [ChartDataLabels, emptyGraphPlugin],
      options: {
        locale: document.documentElement.lang,
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          y: {
            display: true,
            title: {
              display: true,
              font,
              text: data.datasets.length > 1
                ? this.dataset.title
                : data.datasets[0].label,
            },
            suggestedMin: parseInt(this.dataset.y) || 0,
            ticks: {
              beginAtZero: true,
              callback: (value) => this.axisLabels(value, this.dataset.unit),
            },
          },
          x: {
            type: "time",
            time: {
              unit: this.dataset.timescale,
              round: this.dataset.timescale,
              displayFormats: {
                month: "MMM 'YY",
                week: "[KW] w",
              },
              tooltipFormat: this.dataset.timescale === "month"
                ? "MMM YYYY"
                : "[KW] ww YYYY [(]dd, D. MMM [–] So[)]",
            },
          },
        },
        plugins: {
          legend: data.datasets.length > 1
            ? {
              onClick: this.clickSwitchDatasets,
              position: "bottom",
              align: "start",
              labels: {
                usePointStyle: true,
                padding: 20,
                generateLabels: this.generateLabels.bind(this),
                font,
              },
            }
            : false,
          datalabels: {
            display: "auto",
            backgroundColor: function (context) {
              return color(context.dataset.backgroundColor).alpha(1).rgbString()
            },
            formatter: (value) =>
              value.y !== 0 ? this.axisLabels(value.y, this.dataset.unit) : null,
            borderRadius: type === "line" ? 6 : 3,
            color: "white",
            font: {
              ...font,
              weight: "bold",
              lineHeight: 1,
              size: font.size * 0.8,
            },
            padding: type === "line" ? 6 : 3,
          },
          tooltip: {
            usePointStyle: true,
            callbacks: {
              label: (tooltipItem) => {
                const label = capFirst(tooltipItem.dataset.label || "")
                if (tooltipItem.parsed.y !== null) {
                  return label + ": " +
                    this.axisLabels(tooltipItem.parsed.y, this.dataset.unit)
                }
              },
            },
          },
          annotation: this.getAnnotation(),
        },
        interaction: {
          mode: "index",
          intersect: false,
        },
        animation: {
          onComplete: this.prepareImageDownload,
        },
      },
    })
  }

  prepareImageDownload(element) {
    const chartName = element.chart.canvas.dataset.chartName
    const link = document.getElementById(`chart-download-${chartName}`)
    link.download = `${chartName}-${new Date().toISOString().split("T")[0]}.png`
    link.href = element.chart.toBase64Image()
  }

  getAnnotationLabel(label, description) {
    return {
      text: capFirst(label) + ": " + description,
      fillStyle: "black",
      pointStyle: "dash",
      lineDash: [3, 2],
      lineWidth: 2.5,
    }
  }

  generateLabels(chart) {
    const labels = Chart.defaults.plugins.legend.labels.generateLabels(chart)
    labels.forEach((label, index) => {
      const labelTitle = capFirst(chart.data.datasets[index].label)
      const footnote = chart.data.datasets[index].footnote
      label.fillStyle = chart.data.datasets[index].hidden
        ? "#ccc"
        : color(label.fillStyle).alpha(1).rgbString()
      label.strokeStyle = chart.data.datasets[index].hidden
        ? "#ccc"
        : color(label.fillStyle).alpha(1).rgbString()
      label.hidden = false
      const fontColor = chart.data.datasets[index].hidden
        ? color(globalThis.getComputedStyle(document.documentElement).color).hexString()
        : color(label.fillStyle).alpha(1).darken(0.5).saturate(1).hexString()
      const pointStyle = new Image(16, 22)
      const unchecked =
        `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 22"><circle cx="8" cy="8" r="6.5" stroke="%23${
          fontColor.slice(1, 7)
        }" stroke-width="1" fill="none"/></svg>`
      const checked =
        `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 22"><circle cx="8" cy="8" r="4" fill="%23${
          fontColor.slice(1, 7)
        }"/><circle cx="8" cy="8" r="6.5" stroke="%23${
          fontColor.slice(1, 7)
        }" stroke-width="1" fill="none"/></svg>`
      pointStyle.src = chart.data.datasets[index].hidden ? unchecked : checked
      label.pointStyle = pointStyle
      label.text = footnote ? `${labelTitle}: ${footnote}` : labelTitle
      label.fontColor = fontColor
    })
    if (this.dataset.annotation) {
      const annotation = JSON.parse(this.dataset.annotation)
      const [annotationLabel, annotationDescription] = annotation.slice(2)
      labels.push(this.getAnnotationLabel(annotationLabel, annotationDescription))
    }
    return labels
  }

  getLineAnnotation(lineAnnotation) {
    const annotation = JSON.parse(lineAnnotation)
    const [annotationStart, annotationEnd] = annotation.slice(0, 2).map((x) =>
      Math.ceil(x)
    )
    const [annotationLabel] = annotation.slice(2, -1)
    return {
      scaleId: "y",
      type: "line",
      borderColor: "black",
      borderDash: [6, 6],
      borderWidth: 2,
      yMin: annotationStart,
      yMax: annotationEnd,
      label: {
        backgroundColor: color("#000").alpha(0.6).rgbString(),
        color: "#fff",
        content: capFirst(annotationLabel),
        display: true,
        position: "end",
      },
    }
  }

  getAnnotation() {
    return {
      annotations: this.dataset.annotation
        ? [this.getLineAnnotation(this.dataset.annotation)]
        : [],
    }
  }

  axisLabels(value, unit) {
    if (unit) {
      return `${localize(value)}\xa0${unit}`
    } else {
      return localize(value)
    }
  }

  clickSwitchDatasets(evt, legendItem, legend) {
    if (evt.native.shiftKey) {
      legend.chart.data.datasets[legendItem.datasetIndex].hidden = !legend
        .chart.data.datasets[legendItem.datasetIndex].hidden
    } else {
      for (const [index, dataset] of legend.chart.data.datasets.entries()) {
        dataset.hidden = index !== legendItem.datasetIndex
      }
    }
    for (const dataset of legend.chart.data.datasets) {
      dataset.fill = legend.chart.data.datasets.filter((dataset) =>
          !dataset.hidden
        ).length > 1
        ? null
        : "origin"
    }
    legend.chart.update()
  }
}

customElements.define("time-series-chart", TimeSeriesChart, { extends: "canvas" })
